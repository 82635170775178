import { ShopItem } from '../events/shop';
import { TicketSetting } from '../tickets/ticket-setting';
import { PromoCode } from './promocode';

export interface CartItemDTO {
  ticketSettingId: number;
  quantity: number;
}

export interface CartShopItemDTO {
  shopItemId: number;
  quantity: number;
}

export interface CreateCartDTO {
  items: CartItemDTO[];
  promoCodeId?: number;
}

export interface ShopItemDTO {
  shopItemId: number;
  quantity: number;
}

export interface CartDTO {
  items?: CartItemDTO[];
  shopItems?: ShopItemDTO[];
  promoCodeId?: number;
}

export interface CartItem {
  id: number;
  quantity: number;
  price: number;
  ticketSettingId: number;
  shopItemId?: number;
  shopItem: ShopItem;
  createdAt: Date;
  updatedAt: Date;
  ticketSetting: TicketSetting;
}

export enum CartStatus {
  Pending = 'PENDING',
  Completed = 'COMPLETED',
}

export interface Cart {
  id: number;
  eventId: number;
  userId: number;
  orderId?: number;
  status: CartStatus;
  total: number;
  paymentIntentId: string;
  subtotal: number;
  items: CartItem[];
  shopItems?: ShopItemDTO[];
  promoCodeId?: number;
  promoCode?: PromoCode;
  createdAt: Date;
  updatedAt: Date;
  fees: number;
  discount: number;
}

export interface CartResponse extends Cart {
  client_secret?: string;
}
